<template>
    <v-simple-table>
        <tbody>
            <template v-if="items">
                <tr v-for="item in items" v-bind:key="item.id">
                    <td style="width:80px;" v-if="$vuetify.breakpoint.smAndUp">
                        <v-img
                            v-if="item.product"
                            height="80"
                            width="80"
                            :src="item.product.thumbnail | toCDNPath"
                            contain
                        ></v-img>
                    </td>
                    <td>{{ item.quantity }} x {{ item.name }}</td>
                    <td v-if="$vuetify.breakpoint.smAndUp">{{ item.price | toCurrency }}</td>
                    <td>{{ (item.price * item.quantity) | toCurrency }}</td>
                </tr>
            </template>

            <template v-else>
                <tr>
                    <td>El pedido esta vacio.</td>
                </tr>
            </template>
        </tbody>
    </v-simple-table>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
            };
        },

        props: {
            items: Array
        }
    };
</script>

<style lang="scss" scoped>

</style>
