<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-text-field
                    id="cc-csc"
                    autocomplete="cc-csc"
                    x-autocompletetype="cc-csc"
                    label="CVC"
                    v-model="ccCSC"
                ></v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    /* global Mercadopago */
    export default {
        components: {
        },

        data() {
            return {
                ccCSC: null
            };
        },

        props: {
            method: {
                type: Object
            },

            amount: {
                type: Number
            },

            order: {
                type: Object
            }
        },

        methods: {
            async submit() {
                if(this.ccCSC < 3) {
                    return null;
                }

                let mpToken = await this._getMPToken({
                    token: this.method.token,
                    csc: this.ccCSC
                });

                await this.$store.dispatch('order/addPayment', {
                    order: this.order,
                    payment: {
                        payment_method_id: 5,
                        payment_amount: this.amount,
                        payment_data: {
                            cc_type: this.method.name,
                            cc_last: this.method.last,
                            cc_token: mpToken.token
                        }
                    }
                });
            },

            _getMPToken(cc) {
                return new Promise((resolve, reject) => {
                    let mpFormData = {
                        cardId: cc.token,
                        securityCode: cc.csc
                    };

                    try {
                        Mercadopago.clearSession();
                        Mercadopago.createToken(mpFormData, (status, response) => {
                            if(status !== 200 && status !== 201) {
                                reject(new Error(response));
                            }
                            else {
                                resolve({
                                    last_four_digits: response.last_four_digits,
                                    token: response.id
                                });
                            }
                        });
                    }
                    catch (err) {
                        console.log(err);
                    }
                });
            }
        }
    };
</script>
