<template>
    <v-col v-if="isLoading">
        <v-row justify="center">
            <tui-loader></tui-loader>
        </v-row>
    </v-col>
    <div v-else>
        <order-payment-cancellation-drawer
            v-model="selectedPayment"
        />

        <v-simple-table>
            <tbody>
                <template v-if="hasPayments">
                    <tr v-bind:key="payment.id" v-for="payment in order.payments">
                        <td>Pago #{{ payment.id }} efectuado el {{ payment.createdAt | moment('DD/MM/YY h:mm:ss a') }}
                            <span v-if="payment.createdBy">por {{ payment.createdBy.name }}</span>
                        </td>
                        <td>
                            {{ payment.paymentMethod.name }}
                            <!-- <span v-if="payment.paymentType"> ({{ payment.paymentType }})</span> -->
                        </td>
                        <td v-if="$vuetify.breakpoint.smAndUp">{{ payment.authorizationCode }}</td>
                        <td>{{ payment.total | toCurrency }}</td>
                        <td>
                            <v-chip
                                label
                                x-small-chip
                                :color="getPaymentStatusColor(payment.status)"
                                text-color="white"
                            >
                                {{ getPaymentStatusName(payment.status) }}
                            </v-chip>
                        </td>
                        <td>
                            <!-- <v-btn icon @click="onReturnPaymentSelected(payment)" :disabled="!canReturnPayment(payment)"><v-icon>mdi-credit-card-refund</v-icon></v-btn> -->
                            <v-btn icon @click="selectedPayment = payment" :disabled="!canReturnPayment(payment)"><v-icon>mdi-credit-card-refund</v-icon></v-btn>
                        </td>
                    </tr>
                </template>

                <template v-else>
                    <tr>
                        <td>No se registran pagos para el pedido.</td>
                    </tr>
                </template>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
    import TuiLoader from '@/ui/TUILoader';
    import OrderPaymentCancellationDrawer from './OrderPaymentCancellationDrawer.vue';

    export default {
        components: {
            TuiLoader,
            OrderPaymentCancellationDrawer
        },

        data() {
            return {
                isLoading: false,
                selectedPayment: null
            };
        },

        props: {
            order: Object
        },

        computed: {
            hasPayments() {
                return this.order.payments != null && this.order.payments.length > 0;
            },

            showPaymentCancellationDrawer() {
                return this.selectedPayment !== null;
            }
        },

        watch: {

        },

        methods: {
            getPaymentStatusName(state) {
                switch (state) {
                case 'approved':
                    return 'Aprobado';
                case 'rejected':
                    return 'Rechazado';
                case 'processing':
                    return 'Pendiente';
                case 'refunded':
                    return 'Devuelto';
                }
            },

            getPaymentStatusColor(state) {
                switch (state) {
                case 'approved':
                    return 'green';
                case 'rejected':
                    return 'red';
                case 'processing':
                    return 'yellow';
                case 'refunded':
                    return 'orange';
                }
            },

            canReturnPayment(payment) {
                return payment.paymentType === 'mercadopago' && payment.status === 'approved';
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
