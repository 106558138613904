<template>
    <v-navigation-drawer
        v-model="drawerActive"
        right
        fixed
        temporary
        width="600"
    >
        <v-container class="pa-10" v-if="order">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="onCancelSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline" style="white-space: nowrap">Cambiar envio #{{ order.id }}</span>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-select
                        label="Características"
                        v-model="deliveryFeatures"
                        :items="vehicleFeatures"
                        :disabled="!customer"
                        item-text="name"
                        item-value="id"
                        return-object
                        multiple
                    ></v-select>
                </v-col>

                <v-col cols="12">
                    <customer-shipping-address-select
                        :customer="customer"
                        v-model="selectedShippingAddress"
                    />
                </v-col>

                <v-col cols="12">
                    <order-delivery-schedule-select
                        :zone="deliveryZone"
                        :address="selectedShippingAddress"
                        :features="deliveryFeatures"
                        v-model="selectedDeliverySchedule"
                    />
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="mr-4 mb-4">
                <v-spacer></v-spacer>

                <v-btn color="success" @click="onConfirmSelected" :loading="isSaving" :disabled="!canSave">
                    Guardar
                </v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import CustomerShippingAddressSelect from '@/components/Customer/CustomerShippingAddressSelect';
    import OrderDeliveryScheduleSelect from '@/components/OrderDeliveryScheduleSelect';

    export default {
        components: {
            OrderDeliveryScheduleSelect,
            CustomerShippingAddressSelect
        },

        props: {
            order: {
                type: Object,
                default: null
            },

            active: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                isLoading: false,
                isSaving: false,

                selectedShippingAddress: null,
                deliveryZone: null,
                selectedDeliverySchedule: null,

                deliveryFeatures: []
            };
        },

        computed: {
            drawerActive: {
                get() {
                    return this.active;
                },

                set(val) {
                    this.$emit('update:active', val);
                }
            },

            customer() {
                if(this.order) {
                    return this.$store.getters['customer/getById'](this.order.customer);
                }

                return null;
            },

            vehicleFeatures() {
                return this.$store.getters['vehicleFeatures/getAll']();
            },

            canSave() {
                if(!this.selectedDeliverySchedule) {
                    return false;
                }

                if(this.selectedDeliverySchedule.full) {
                    return false;
                }

                return true;
            }
        },

        watch: {
            selectedShippingAddress: {
                handler(value) {
                    this.deliveryZone = null;

                    if(value) {
                        this.$api.getZonesByLocation(value.location).then((zone) => {
                            this.deliveryZone = zone;
                        });
                    }
                }
            }
        },

        mounted() {
            this.$store.dispatch('vehicleFeatures/fetchAll');
        },

        methods: {
            async onConfirmSelected(value) {
                this.isSaving = true;

                try {
                    await this.$store.dispatch('order/updateDelivery', {
                        order: this.order,
                        address: this.selectedShippingAddress,
                        date: this.selectedDeliverySchedule.date,
                        start: this.selectedDeliverySchedule.start,
                        end: this.selectedDeliverySchedule.end,
                        vehicleFeatures: this.deliveryFeatures.map(item => item.id)
                    });

                    await this.$store.dispatch('order/fetchShipments', { order: this.order });

                    this.$emit('update:active', false);
                }
                finally {
                    this.isSaving = false;

                    this.selectedShippingAddress = null;
                    this.deliveryZone = null;
                    this.selectedDeliverySchedule = null;
                    this.deliveryFeatures = [];
                }
            },

            onCancelSelected(value) {
                this.$emit('update:active', false);
            }
        }
    };
</script>
