<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-text-field
                    label="Número de cheque"
                    v-model="checkNumber"
                />
            </v-col>

            <v-col cols="12">
                <v-menu
                    v-model="dateMenuOpen"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            label="Fecha"
                            color="primary"
                            v-model="checkDate"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>

                    <v-date-picker
                        v-model="checkDate"
                        @input="dateMenuOpen = false"
                        no-title
                        :first-day-of-week="1"
                        locale="es-AR"
                        event-color="green lighten-1">
                    </v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12">
                <v-menu
                    v-model="expirationDateMenuOpen"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            label="Fecha de Vencimiento"
                            color="primary"
                            v-model="checkExpirationDate"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>

                    <v-date-picker
                        v-model="checkExpirationDate"
                        @input="expirationDateMenuOpen = false"
                        no-title
                        :first-day-of-week="1"
                        locale="es-AR"
                        event-color="green lighten-1">
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        components: {
        },

        data() {
            return {
                expirationDateMenuOpen: false,
                dateMenuOpen: false,

                checkNumber: '',
                checkDate: '',
                checkExpirationDate: ''
            };
        },

        props: {
            order: {
                type: Object
            },

            amount: {
                type: Number
            }
        },

        computed: {
        },

        watch: {
            formData: {
                deep: true,
                handler(val) {
                    if(val) {
                        this.$emit('input', val);
                    }
                }
            }
        },

        methods: {
            async submit() {
                await this.$store.dispatch('order/addPayment', {
                    order: this.order,
                    payment: {
                        payment_method_id: 2,
                        payment_amount: this.amount,
                        payment_data: {
                            check_number: this.checkNumber,
                            check_date: this.checkDate,
                            check_expiration_date: this.checkExpirationDate
                        }
                    }
                });
            }
        }
    };
</script>
