<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <cash-account-select
                    v-model="cashAccount"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import CashAccountSelect from '@/components/Billing/CashAccountSelect';

    export default {
        components: {
            CashAccountSelect
        },

        data() {
            return {
                cashAccount: null
            };
        },

        props: {
            order: {
                type: Object
            },

            amount: {
                type: Number
            }
        },

        methods: {
            async submit() {
                await this.$store.dispatch('order/addPayment', {
                    order: this.order,
                    payment: {
                        payment_method_id: 1,
                        payment_amount: this.amount,
                        payment_data: {
                            cash_account_id: this.cashAccount
                        }
                    }
                });
            }
        }
    };
</script>
