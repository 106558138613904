<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="500"
    >
        <v-container class="px-6 fill-height" v-if="value">
            <v-row class="fill-height">
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="onCancelSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline" style="white-space: nowrap">Devolver pago #{{ value.id }}</span>
                    </v-row>

                    <v-row>
                        <v-col>
                            Esta seguro que desea pedir la devolución del pago #{{ value.id }} por un monto de {{ value.total | toCurrency }}
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="orange" dark block @click="onConfirmSelected" :loading="isCancelling">Devolver</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    export default {
        props: {
            value: {
                type: Object,
                default: null
            }
        },

        data() {
            return {
                isCancelling: false
            };
        },

        computed: {
            active: {
                get: function() {
                    return this.value !== null;
                },
                set: function(val) {
                    console.log(val);

                    if(!val) {
                        this.$emit('input', null);
                    }
                }
            }
        },

        watch: {
        },

        methods: {
            async onConfirmSelected(payment) {
                this.isCancelling = true;

                try {
                    await this.$store.dispatch('payments/return', {
                        payment: this.value
                    });

                    this.$store.dispatch('app/setStatusMessage', 'Se ha pedido la devolución del pago');
                }
                catch {

                }
                finally {
                    this.isCancelling = false;

                    this.$emit('input', null);
                }
            },

            onCancelSelected(value) {
                this.$emit('input', null);
            }
        }
    };
</script>
