<template>
    <template-layout>
        <template slot="drawers">
            <order-delivery-drawer
                :active.sync="showEditDeliveryForm"
                :order="order"
            />

            <order-payment-drawer
                :active.sync="showPaymentForm"
                :order="order"
                :customer="customer"
            />

            <order-cancellation-drawer
                v-model="showCancelDialog"
                :order="order"
            />
        </template>

        <template slot="content">
            <v-container fluid v-if="isLoading && !order" class="fill-height">
                <v-row>
                    <v-col cols="12">
                        <v-row justify="center" align="center">
                            <tui-loader></tui-loader>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>

            <v-container v-else-if="order">
                <v-row>
                    <v-col cols="12">
                        <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                            <v-toolbar-title class="mx-0 px-0">
                                <span class="headline" style="white-space: nowrap">Pedido #{{ id }}</span>

                                <tui-order-state-chip :state="order.state" class="mx-4"></tui-order-state-chip>

                                <tui-order-payment-status-chip v-if="order.total > 0" :order="order"></tui-order-payment-status-chip>

                                <v-chip
                                    v-if="order.orderType && order.orderType !== 'normal'"
                                    label
                                    x-small-chip
                                    color="orange"
                                    text-color="white"
                                >
                                    {{ orderType }}
                                </v-chip>
                            </v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-toolbar-title>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon :disabled="!canAddPayment" @click="showPaymentForm = true" v-on="on">
                                            <v-icon>mdi-credit-card-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Pagar</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon :disabled="!canEditDelivery" @click="showEditDeliveryForm = true" v-on="on">
                                            <v-icon>mdi-truck-delivery-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Cambiar entrega</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon :disabled="!canCancel" @click="showCancelDialog = true" v-on="on">
                                            <v-icon>mdi-alert-circle-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Anular pedido</span>
                                </v-tooltip>
                            </v-toolbar-title>
                        </v-toolbar>
                    </v-col>
                </v-row>

                <v-row>
                    <!-- Left Column -->
                    <v-col cols="12" lg="8">
                        <v-card class="mb-7">
                            <v-card-text class="body-1">
                                <v-list v-if="order" dense class="t-info-list">
                                    <v-list-item>
                                        <v-list-item-content>Cliente</v-list-item-content>
                                        <v-list-item-content class="align-end text-right">
                                            <router-link v-if="customer" :to="{ name: 'customerView', params: { id: order.customer } }">
                                                {{ customer.fullName | capitalize }}
                                            </router-link>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-content>Fecha</v-list-item-content>
                                        <v-list-item-content class="align-end">{{ order.date | moment('D/M/Y') }}</v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-content>Subtotal</v-list-item-content>
                                        <v-list-item-content class="align-end">{{ order.subtotal | toCurrency }}</v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-content>Descuento</v-list-item-content>
                                        <v-list-item-content class="align-end">- {{ order.discount | toCurrency }}</v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-content>Total</v-list-item-content>
                                        <v-list-item-content class="align-end">{{ order.total | toCurrency }}</v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>

                        <v-card class="mb-7">
                            <v-card-title>Entregas</v-card-title>

                            <v-card-text class="body-1 ma-0 pa-0">
                                <order-shipments :order="order"></order-shipments>
                            </v-card-text>
                        </v-card>

                        <v-card class="mb-7">
                            <v-card-title>Datos de Facturación</v-card-title>

                            <v-card-text class="body-1">
                                <v-list dense class="t-info-list">
                                    <v-list-item>
                                        <v-list-item-content>Nro de Factura</v-list-item-content>
                                        <v-list-item-content class="text-right" v-if="order && order.invoice">
                                            <a @click="onInvoiceSelected(order.invoice)">{{ order.invoice.invoiceFormattedNumber }}</a>
                                        </v-list-item-content>
                                        <v-list-item-content v-else>Sin Factura</v-list-item-content>
                                    </v-list-item>

                                    <template v-if="order.billingAddress">
                                        <v-list-item>
                                            <v-list-item-content>Datos de Facturación</v-list-item-content>
                                            <v-list-item-content>{{ order.billingAddress.name }}, {{ order.billingAddress.address }}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>Tipo IVA</v-list-item-content>
                                            <v-list-item-content>{{ order.billingAddress.ivaCondition }}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>CUIT / DNI</v-list-item-content>
                                            <v-list-item-content>{{ order.billingAddress.cuit }}</v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-else>
                                        <v-list-item>
                                            <v-list-item-content>Tipo IVA</v-list-item-content>
                                            <v-list-item-content>Consumidor Final</v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-card-text>
                        </v-card>

                        <v-card class="mb-7">
                            <v-card-title>Pagos</v-card-title>

                            <v-card-text class="body-1">
                                <order-payments :order="order"></order-payments>
                            </v-card-text>
                        </v-card>

                        <v-card class="mb-7">
                            <v-card-title>Detalle del pedido</v-card-title>

                            <v-card-text class="body-1">
                                <order-contents v-bind:items="order.items"></order-contents>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- Right Column-->
                    <v-col cols="12" lg="4">
                        <v-card class="mb-7">
                            <v-card-title>Actividad</v-card-title>

                            <v-card-text>
                                <order-activity :order="order"></order-activity>
                            </v-card-text>
                        </v-card>

                        <v-card class="mb-7">
                            <v-card-title>Comentarios</v-card-title>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <order-comments :order="order"></order-comments>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            class="comment-field"
                                            outline
                                            v-model="fieldComment"
                                            label="Escribir comentario..."
                                            auto-grow
                                            solo hide-details
                                            @keydown.enter="onSaveCommentSelected"
                                            :loading="isSaving"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout';

    import TuiOrderStateChip from '@/ui/TUIOrderStateChip';
    import TuiLoader from '@/ui/TUILoader';
    import TuiOrderPaymentStatusChip from '@/ui/TUIOrderPaymentStatusChip';

    import OrderActivity from '@/components/Order/OrderActivity';
    import OrderComments from '@/components/Order/OrderComments';
    import OrderPayments from './OrderPayments';
    import OrderShipments from './OrderShipments';
    import OrderContents from './OrderContents';

    import OrderCancellationDrawer from './OrderCancellationDrawer';
    import OrderPaymentDrawer from './OrderPaymentDrawer';
    import OrderDeliveryDrawer from './OrderDeliveryDrawer';

    export default {
        components: {
            OrderDeliveryDrawer,
            OrderCancellationDrawer,
            OrderPaymentDrawer,
            TuiOrderPaymentStatusChip,
            OrderPayments,
            OrderShipments,
            TemplateLayout,
            TuiLoader,
            TuiOrderStateChip,
            OrderContents,
            OrderActivity,
            OrderComments
        },

        props: {},

        data() {
            return {
                isSaving: false,

                activeTab: null,

                showPaymentForm: false,
                showEditDeliveryForm: false,
                showCancelDialog: false,

                fieldComment: ''
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['order/isLoading']();
            },

            order() {
                return this.$store.getters['order/getById'](parseInt(this.$route.params.id));
            },

            id() {
                if(this.order) {
                    return this.order.id;
                }

                return null;
            },

            customer() {
                if(this.order) {
                    return this.$store.getters['customer/getById'](this.order.customer);
                }

                return null;
            },

            orderType() {
                return this.order.orderType === 'normal' ? 'Normal' : 'Reposición';
            },

            canEditDelivery() {
                return this.order.state === 'state.open';
            },

            canCancel() {
                return this.order.state === 'state.open' && !this.isOutForShipping;
            },

            canAddPayment() {
                return this.order.total > this.order.paidUp;
            },

            isOutForShipping() {
                for(let shipment of this.order.shipments) {
                    if(shipment.state === 'state.packed' || shipment.state === 'state.transit') {
                        return true;
                    }
                }

                return false;
            }
        },

        watch: {
            $route: 'fetchData'
        },

        timers: {
            refreshTimer: {
                time: 30000,
                autostart: true,
                repeat: true
            }
        },

        async mounted() {
            this.fetchData();
        },

        methods: {
            refreshTimer() {
                this.fetchData();
            },

            async fetchData() {
                await this.$store.dispatch('order/fetchOne', { id: parseInt(this.$route.params.id) });

                this.$store.dispatch('customer/fetchOne', this.order.customer);
                this.$store.dispatch('order/fetchPayments', { order: this.order });
                this.$store.dispatch('order/fetchShipments', { order: this.order });
                this.$store.dispatch('order/fetchComments', { order: this.order });
                this.$store.dispatch('order/fetchActivity', { order: this.order });
            },

            async onInvoiceSelected(invoice) {
                let data = await this.$api.getBlob('/api/invoices/' + invoice.id);

                var file = new Blob([data], { type: 'application/pdf' });
                var fileURL = window.URL.createObjectURL(file);
                window.open(fileURL);
            },

            onBackSelected() {
                if(window.history.length > 2) {
                    this.$router.go(-1);
                }
                else {
                    this.$router.push({ name: 'orderList' });
                }
            },

            async onSaveCommentSelected() {
                this.isSaving = true;

                await this.$store.dispatch('order/addComment', { order: this.order, comment: this.fieldComment });

                this.isSaving = false;
                this.fieldComment = '';
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-subheader {
        font-weight: bold;
    }

    .comment-field {
        ::v-deep .v-input__slot {
            box-shadow: none !important;
            border-radius: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.17);
        }
    }
</style>
