<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="500"
    >
        <v-container class="px-6 fill-height" v-if="order">
            <v-row class="fill-height">
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="onCancelSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline" style="white-space: nowrap">Anular pedido #{{ order.id }}</span>
                    </v-row>

                    <v-row>
                        <v-col>
                            Esta seguro que desea anular el pedido?
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="red" dark block @click="onConfirmSelected" :loading="isCancelling">Anular</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    export default {
        props: {
            order: {
                type: Object,
                default: null
            },

            value: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                active: false,
                isCancelling: false
            };
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            }
        },

        methods: {
            async onConfirmSelected() {
                this.isCancelling = true;

                try {
                    await this.$store.dispatch('order/cancel', { order: this.order });

                    await this.$store.dispatch('order/fetchShipments', { order: this.order });
                    await this.$store.dispatch('order/fetchActivity', { order: this.order });

                    this.showCancelDialog = false;
                    this.active = false;
                }
                finally {
                    this.isCancelling = false;
                }
            },

            onCancelSelected(value) {
                this.active = false;
            }
        }
    };
</script>
