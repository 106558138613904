<template>
    <v-select
        label="Caja"
        :loading="isLoading"
        v-model="selectedCashAccount"
        :items="cashAccounts"
        item-text="name"
        item-value="id"
        required
    >
    </v-select>
</template>

<script>
    export default {
        components: {},

        props: [ 'value', 'readonly' ],

        data() {
            return {
                selectedCashAccount: null
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['cashAccounts/isLoading']();
            },

            cashAccounts() {
                return this.$store.getters['cashAccounts/getOwned']();
            }
        },

        watch: {
            cashAccounts(v) {
                if(v && v.length > 0) {
                    this.selectedCashAccount = v[0].id;
                }
            },

            selectedCashAccount(v) {
                this.$emit('input', v);
            }
        },

        mounted() {
            this.$store.dispatch('cashAccounts/fetchAll');
        }
    };
</script>

<style lang="scss" scoped>

</style>
