<template>
    <v-list v-if="order.activity && order.activity.length > 0" class="py-0">
        <v-list-item
            class="px-0"
            v-for="item in order.activity"
            :key="item.id"
        >
            <v-list-item-avatar color="indigo">
                <span class="white--text headline" v-if="item.createdBy">{{ item.createdBy.name | toInitials }}</span>
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title><span class="font-weight-bold" v-if="item.createdBy">{{ item.createdBy.name }}</span><span v-else>Se </span> {{ item.name }} <span class="caption grey--text">{{ item.createdAt | moment('from', 'now') }}</span></v-list-item-title>
                <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>

            </v-list-item-action>
        </v-list-item>
    </v-list>
    <v-list v-else>
        <v-list-item class="px-0">
            <v-list-item-content>No hay registros de actividad</v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
    export default {
        components: {},

        data() {
            return {
            };
        },

        props: {
            order: Object
        },

        methods: {
        }
    };
</script>

<style lang="scss" scoped>
    .v-list {
        background: none;
    }
</style>
