<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-text-field
                    label="Número de comprobante"
                    v-model="authorizationCode"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        components: {
        },

        data() {
            return {
                authorizationCode: ''
            };
        },

        props: {
            order: {
                type: Object
            },

            amount: {
                type: Number
            }
        },

        computed: {
        },

        watch: {
            formData: {
                deep: true,
                handler(val) {
                    if(val) {
                        this.$emit('input', val);
                    }
                }
            }
        },

        methods: {
            async submit() {
                await this.$store.dispatch('order/addPayment', {
                    order: this.order,
                    payment: {
                        payment_method_id: 4,
                        payment_amount: this.amount,
                        payment_data: {
                            authorization_code: this.authorizationCode
                        }
                    }
                });
            }
        }
    };
</script>
