<template>
    <v-navigation-drawer
        v-model="drawerActive"
        right
        temporary
        fixed
        width="500"
    >
        <v-container class="px-6" v-if="order">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="onCancelSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline" style="white-space: nowrap">Agregar pago</span>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="Monto"
                        v-model="amountPaid"
                        prefix="$"
                        :hint="hint"
                        persistent-hint
                        :error="errors.has('amountPaid')"
                        :error-messages="errors.get('amountPaid')"
                    ></v-text-field>
                </v-col>

                <v-col cols="12">
                    <v-select
                        label="Forma de pago"
                        v-model="selectedPaymentMethod"
                        :items="combinedPaymentMethods"
                        item-text="displayName"
                        return-object
                        required
                    >
                    </v-select>
                </v-col>
            </v-row>

            <v-row>
                <component
                    ref="paymentForm"
                    v-bind:is="currentFormComponent"
                    :order="order"
                    :amount.sync="amountPaid"
                    :method="selectedPaymentMethod"
                ></component>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="mr-4 mb-4">
                <v-spacer></v-spacer>

                <v-btn color="success" @click="onConfirmSelected" :loading="isSaving">Ingresar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import PaymentCashForm from '@/components/Payment/PaymentCashForm.vue';
    // import PaymentCreditCardForm from '@/components/Payment/PaymentCreditCardForm.vue';
    import PaymentPosnetForm from '@/components/Payment/PaymentPosnetForm.vue';
    import PaymentSavedCreditCardForm from '@/components/Payment/PaymentSavedCreditCardForm.vue';
    import PaymentWireTransferForm from '@/components/Payment/PaymentWireTransferForm.vue';
    import PaymentCheckForm from '@/components/Payment/PaymentCheckForm.vue';
    import PaymentMercadoLinkForm from '@/components/Payment/PaymentMercadoLinkForm.vue';

    export default {
        components: {
            PaymentCashForm,
            // PaymentCreditCardForm,
            PaymentPosnetForm,
            PaymentWireTransferForm,
            PaymentSavedCreditCardForm,
            PaymentCheckForm,
            PaymentMercadoLinkForm
        },

        props: {
            order: {
                type: Object,
                default: null
            },

            customer: {
                type: Object,
                default: null
            },

            active: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                isLoading: false,
                isSaving: false,

                paymentMethods: [],
                savedPaymentMethods: [],

                amountPaid: 0,
                selectedPaymentMethod: null,

                errors: new Map()
            };
        },

        computed: {
            combinedPaymentMethods() {
                let saved = [];
                for(let s of this.savedPaymentMethods) {
                    s.displayName = s.name + ' terminada en ' + s.last;
                    saved.push(s);
                }

                let methods = [];
                for(let s of this.paymentMethods) {
                    s.displayName = s.name;
                    methods.push(s);
                }

                return [ ...methods, ...saved ];
            },

            hint() {
                return 'Pendiente de pago: ' + this.$options.filters.toCurrency(this.order.total);
            },

            drawerActive: {
                get() {
                    return this.active;
                },

                set(val) {
                    this.selectedPaymentMethod = null;
                    this.$emit('update:active', val);
                }
            },

            currentFormComponent() {
                if(this.selectedPaymentMethod) {
                    switch (this.selectedPaymentMethod.id) {
                    case 1:
                        return 'PaymentCashForm';
                    case 2:
                        return 'PaymentCheckForm';
                    case 4:
                        return 'PaymentPosnetForm';
                    case 5:
                        if(this.selectedPaymentMethod.last) {
                            return 'PaymentSavedCreditCardForm';
                        }
                        else {
                            return 'PaymentCreditCardForm';
                        }
                    case 6:
                        return 'PaymentWireTransferForm';
                    case 8:
                        return 'PaymentMercadoLinkForm';
                    default:
                        return '';
                    }
                }
                return '';
            }
        },

        watch: {
            order: {
                immediate: true,
                handler(val, prev) {
                    if(!val) {
                        return;
                    }

                    this.amountPaid = this.order.total - this.order.paidUp;
                }
            },

            customer: {
                immediate: true,
                handler(val, prev) {
                    if(!val) {
                        return;
                    }

                    this.isLoading = true;
                    this.$api.getCustomerPaymentMethods(val.id).then((response) => {
                        this.paymentMethods = response.methods;
                        this.savedPaymentMethods = response.saved;
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            }
        },

        methods: {
            async onConfirmSelected(value) {
                this.isSaving = true;
                this.errors.clear();

                try {
                    await this.$refs.paymentForm.submit();

                    await this.$store.dispatch('order/fetchOne', { id: this.order.id });

                    this.$emit('update:active', false);
                }
                catch (err) {
                    if(err.code === 'error.payment.invalid_amount') {
                        this.errors = new Map(this.errors.set('amountPaid', this.$t(err.code)));
                    }
                }
                finally {
                    this.isSaving = false;
                }
            },

            onCancelSelected(value) {
                this.$emit('update:active', false);
            }
        }
    };
</script>
