<template>
    <v-expansion-panels flat multiple class="elevation-0">
        <v-expansion-panel v-bind:key="key" v-for="(value, key) in order.shipments">
            <v-expansion-panel-header class="body-1">
                <span>Entrega #{{ value.id }} del {{ value.date | moment('dddd D MMMM YYYY') | capitalize }} </span>

                <v-spacer/>

                <tui-shipping-state-chip
                    :state="value.state"
                />
            </v-expansion-panel-header>

            <v-expansion-panel-content>
                <v-list dense class="t-info-list" v-if="value && value.shippingAddress">
                    <v-list-item>
                        <v-list-item-content>Requisitos</v-list-item-content>
                        <v-list-item-content>
                            {{ (value.deliveryFeatures && value.deliveryFeatures.length > 0 ? value.deliveryFeatures : [ { name: 'Ninguno' } ]).map(f => f.name).join(', ') }}
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Fecha de Entrega</v-list-item-content>
                        <v-list-item-content v-if="value.date">
                            {{ value.date | moment('D/M/Y') }}
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Horario de Entrega</v-list-item-content>
                        <v-list-item-content>
                            {{ value.startTime }} - {{ value.endTime }}
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Dirección de Entrega</v-list-item-content>
                        <v-list-item-content>
                            {{ value.shippingAddress.address }}
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="value.shippingAddress && (value.shippingAddress.place || value.shippingAddress.area || value.shippingAddress.city)">
                        <v-list-item-content>Localidad / Lugar</v-list-item-content>
                        <v-list-item-content>
                            <template v-if="value.shippingAddress.place">{{ value.shippingAddress.place }}. </template>
                            <template v-if="value.shippingAddress.area">{{ value.shippingAddress.area }}. </template>
                            <template v-if="value.shippingAddress.city">{{ value.shippingAddress.city }}. </template>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="value.shippingAddress.unit">
                        <v-list-item-content>Unidad / Departamento</v-list-item-content>
                        <v-list-item-content>{{ value.shippingAddress.unit }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="value.shippingAddress.notes">
                        <v-list-item-content>Observación</v-list-item-content>
                        <v-list-item-content>{{ value.shippingAddress.notes }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="value.shippingAddress.cellphone || value.shippingAddress.phone">
                        <v-list-item-content>Contacto</v-list-item-content>
                        <v-list-item-content class="text-right">
                            <span style="line-height: 24px;">
                                <template v-if="value.shippingAddress.cellphone"><v-icon class="mr-2">mdi-cellphone</v-icon>{{ value.shippingAddress.cellphone }}</template>
                                <template v-if="value.shippingAddress.phone"><v-icon class="mr-2 ml-4">mdi-phone-classic</v-icon>{{ value.shippingAddress.phone }}</template>
                            </span>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import TuiShippingStateChip from '@/ui/TUIShippingStateChip.vue';

    export default {
        components: {
            TuiShippingStateChip
        },

        data() {
            return {
                isLoading: false
            };
        },

        props: {
            order: Object
        },

        computed: {
            hasShipments() {
                return this.order.shipments != null && this.order.shipments.length > 0;
            }
        },

        watch: {

        },

        methods: {
            getPaymentStatusName(state) {
                switch (state) {
                case 'approved':
                    return 'Aprobado';
                case 'rejected':
                    return 'Rechazado';
                case 'processing':
                    return 'Pendiente';
                }
            },

            getPaymentStatusColor(state) {
                switch (state) {
                case 'approved':
                    return 'green';
                case 'rejected':
                    return 'red';
                case 'processing':
                    return 'yellow';
                }
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
